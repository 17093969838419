footer {
  background-color: #0f3460;
  padding: 80px 0;
  color: #fff;
}

footer .box {
  padding: 0 20px;
}

.mail {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #b8b3b3;
}

footer h1 {
  margin: 0;
  font-size: 25px;
  font-weight: 800;
  width: max-content;
}

footer .logo {
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}
footer h2 {
  font-size: 20px;
}

footer .row {
  justify-content: center;
}

footer p {
  font-size: 16px;
  margin-bottom: 20px;
  opacity: 0.5;
}

footer h2 {
  margin-bottom: 20px;
}

footer ul {
  padding: 0;
}

footer li {
  margin-bottom: 10px;
  opacity: 0.5;
}

ul {
  list-style-type: disc;
  font-weight: bold;
}
